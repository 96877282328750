html, body {
  background-color: #fbfbfb;
}

.open-in-new-link {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

time {
  font-style: italic;
}